import React, { useContext } from 'react'
import Box from '@local/shared/components/System/Box'
import Typography from '@local/shared/components/System/Typography'
import styled, { ThemeContext } from 'styled-components'
import OutboundLink from 'src/components/OutboundLink'
import Rating from 'react-rating'
import starIconImg from 'src/images/star-icon.svg'

const StarSymbol = () => {
  const theme = useContext(ThemeContext)
  return (
    <Box
      as={'svg'}
      src={starIconImg}
      alt={'star rating'}
      width={'2em'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fill={theme.palette.yellow.dark1}
        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
      />
    </Box>
  )
}

const REVIEWS_CONTENT = [
  {
    name: 'Tania Mei-Ling Lee',
    link: 'https://goo.gl/maps/s1SLniGTBixKb5VN9',
    rating: 5,
    content: (
      <>
        Savvas has a really unique and practical approach to weight loss,
        something that has meant a lot to me after constantly jumping from diet
        to diet!
        <br />
        <br />
        His techniques and advice endorse a true shift in mindset and way of
        life. I’m better for it and can’t thank him and his business enough!
      </>
    ),
  },
  {
    name: 'Jack Thomas',
    link: 'https://goo.gl/maps/LVnNb3xjhz8cB2nz8',
    rating: 5,
    content: (
      <>
        I can not speak highly enough of sav and his coaching techniques. He
        helped me drop a stone in time for my wedding. I am not normally a fan
        of exercise and diets, but he made the whole experience with him fun and
        rewarding. He clearly knows his stuff and is a real people person. I can
        say that I lost the weight but I gained a friend :)
      </>
    ),
  },
  {
    name: 'Demelza Tabor',
    link: 'https://goo.gl/maps/P4va4fjrbxJvbwZTA',
    rating: 5,
    content: (
      <>
        Such amazing coaching, completely suited to my individual needs, so
        motivating and helpful. The best weight loss program I’ve ever done...I
        can definitely see the weight loss!! Thank you so much 😊
      </>
    ),
  },
  {
    name: 'Galina Voynova',
    link: 'https://goo.gl/maps/esxzqgZorU6k5yjo8',
    rating: 5,
    content: (
      <>
        You won't meet anyone who knows more about practical health and
        nutrition than Savvas.
        <br />
        <br />
        He has helped me work up my fitness from a beginner level in a very
        short period of time and lose weight and look better than ever. Now I
        can even attend advanced MMA classes with confidence! He has also taught
        me a lot about cooking which has helped me enjoy my diet and nutrition.
        I highly recommend giving WildLife a go to reach your health and weight
        loss goals!
      </>
    ),
  },
  {
    name: 'Abigail Kitson',
    link: 'https://goo.gl/maps/huR4ka7V76wfe2q59',
    rating: 5,
    content: (
      <>
        I feel like a new woman! My health has never been better! Savvas has
        been able to simplify the steps for a healthy lifestyle, supporting me
        with easy actions that have really helped to lose weight and get fitter.
        The programme has also helped my sleep and stress levels, which has made
        a huge difference to my health alongside my weight loss.
      </>
    ),
  },
  {
    name: 'Ryan Gallager (Mythilosphy)',
    link: 'https://goo.gl/maps/mGnktuZENqqkprxo9',
    rating: 5,
    content: (
      <>
        Savvas is not just a coach, if anything that phrase does him a
        disservice. He is inspiring, knowledgeable, approachable and has fully
        lived the experiences he preaches.
        <br />
        <br />
        Savvas helped me through times of struggle regarding my sleep and with
        his guidance and extensive knowledge on intermittent fasting and the
        Ketogenic diet I have been at a weight unseen since my teenage years; 15
        years of bellies and double chins have taken their toll and Savvas has
        unequivocally helped me take back control over so many aspects of my
        life.
        <br />
        <br />
        Seriously could not recommend this dude highly enough. You could
        continue living unconsciously, stuck in the same cycle or you could
        become conscious and with Savvas' help start realising your true
        potential like I have mine! In all truth, I probably wouldn't have
        started doing the things I love had I not had his help, for which I am
        and continue to be forever grateful! Thank you
      </>
    ),
  },
]

const ReviewStyled = styled(Box)`
  position: relative;
  z-index: 1;
  :before {
    content: '“';
    pointer-events: none;
    position: absolute;
    top: -${5 / 32}em;
    left: ${1 / 32}em;
    font-size: 10em;
    color: ${({ theme }) => theme.palette.blue.light2};
    z-index: -10;
    font-family: ${({ theme }) => theme.typography.bodyFontFamily};
  }
  & + & {
    margin-top: 2em;
  }
`

const Reviews = props => {
  return (
    <Box
      bgcolor={'blue.light2'}
      borderColor={'blue.light0'}
      borderTop={'solid 0.5em'}
      py={{ xs: '1em', sm: '2em' }}
      px={{ xs: '1em', sm: '2em' }}
      {...props}
    >
      <Box margin={'auto'} maxWidth={'800px'}>
        <Typography
          variant={'h2'}
          textAlign={'left'}
          scale={10}
          mb={'0.5em'}
          zIndex={2}
        >
          <OutboundLink
            href={'https://g.page/WildLifeCoaching?share'}
            target={'_blank'}
          >
            Google Reviews
          </OutboundLink>
        </Typography>
        {REVIEWS_CONTENT.map((review, i) => (
          <ReviewStyled
            bgcolor={'white'}
            p={'1em'}
            borderRadius={'20px'}
            border={'solid 10px'}
            borderColor={'blue.light1'}
            key={i}
          >
            <Typography variant={'subtitle'} textAlign={'left'} mb={'0.5em'}>
              {review.content}
            </Typography>
            <Box mb={'0.5em'}>
              <OutboundLink href={review.link} target={'_blank'}>
                <Rating
                  initialRating={review.rating}
                  readonly
                  fullSymbol={<StarSymbol />}
                  emptySymbol={<StarSymbol />}
                />
              </OutboundLink>
            </Box>
            <Typography variant={'subtitle'} textAlign={'left'}>
              <OutboundLink href={review.link} target={'_blank'}>
                <strong>{review.name}</strong>
              </OutboundLink>
            </Typography>
          </ReviewStyled>
        ))}
      </Box>
    </Box>
  )
}

export default Reviews

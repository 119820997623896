import React, { forwardRef, useEffect, useRef } from 'react'
import { useMixpanel } from 'gatsby-plugin-mixpanel'

// Nicked from: https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-plugin-google-analytics/src/index.js#L16
// Couldn't figure out how to forwardRef into the library component.
const GoogleAnalyticsOutboundLink = forwardRef((props, ref) => {
  const { eventCategory, eventAction, eventLabel, eventValue, ...rest } = props
  return (
    <a
      {...rest}
      ref={ref}
      onClick={e => {
        if (typeof props.onClick === `function`) {
          props.onClick(e)
        }
        let redirect = true
        if (
          e.button !== 0 ||
          e.altKey ||
          e.ctrlKey ||
          e.metaKey ||
          e.shiftKey ||
          e.defaultPrevented
        ) {
          redirect = false
        }
        if (props.target && props.target.toLowerCase() !== `_self`) {
          redirect = false
        }
        if (window.ga) {
          window.ga(`send`, `event`, {
            eventCategory: eventCategory || `Outbound Link`,
            eventAction: eventAction || `click`,
            eventLabel: eventLabel || props.href,
            eventValue,
            transport: redirect ? `beacon` : ``,
            hitCallback: function() {
              if (redirect) {
                document.location = props.href
              }
            },
          })
        } else {
          if (redirect) {
            document.location = props.href
          }
        }

        return false
      }}
    />
  )
})

const OutboundLink = ({ children, href, ...props }) => {
  const ref = useRef(null)
  const mixpanel = useMixpanel()
  useEffect(() => {
    mixpanel.track_links(ref.current, 'Outbound Link Clicked')
  }, [href, mixpanel, ref])

  return (
    <GoogleAnalyticsOutboundLink ref={ref} {...props} href={href}>
      {children}
    </GoogleAnalyticsOutboundLink>
  )
}

export default OutboundLink
